import { MetaService } from 'app/services/meta.service';
import { LoaderService } from './../../services/loader.service';
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Renderer2,
  Input,
  AfterViewChecked,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FragmentService } from '../../services/fragment.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
declare let APIMaticDevPortal: any;

@Component({
    selector: 'app-api-spec',
    templateUrl: './api-specification.component.html',
    styleUrls: ['./api-specification.component.scss'],
    standalone: false
})
export class ApiSpecificationComponent implements OnInit, AfterViewChecked, OnChanges {
  @Input() apiFamily = 'launchpad';
  @Input() apiName = 'merchant-boarding-nonpos';
  @Input() apiVersion = 'current';
  @Input() isFourOFour = false;
  resourceBase = 'assets/matic/';
  resourceName = 'index.html';
  resoueceUrl;
  iframeUrl = 'assets/matic/non-pos/index.html';
  frameFragment;
  finalSafeUrl: SafeResourceUrl;
  currentRoute;
  isIframeEmbed = true;
  @ViewChild('parent') parent: ElementRef;
  constructor(
    public fragmentService: FragmentService,
    public loaderService: LoaderService,
    public metaService: MetaService,
    private sanitizer: DomSanitizer,
    private location: Location,
    private router: Router,
    private _renderer: Renderer2
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.apiFamily = this.sanatizeInput(this.apiFamily);
    this.apiName = this.sanatizeInput(this.apiName);

    if (this.isIframeEmbed) {
      this.resoueceUrl =
        this.apiName === 'api-specification'
          ? this.resourceBase + this.apiFamily + '/' + this.apiVersion + '/' + this.resourceName
          : this.resourceBase + this.apiFamily + '/' + this.apiName + '/' + this.apiVersion + '/' + this.resourceName;
      // this.resourceBase + this.apiFamily + '/' + this.apiName + '/' + this.apiVersion + '/' + this.resourceName;

      // check and set fragment
      const setFragment = () => {
        this.currentRoute = this.router.url;
        if (this.currentRoute.includes('#')) {
          const fragment = this.currentRoute.split('#');
          const fragmentPart = this.sanatizeInput(fragment[1]);
          this.resoueceUrl = this.resoueceUrl + '#' + fragmentPart;
          this.finalSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.resoueceUrl);
        } else {
          this.finalSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.resoueceUrl);
        }
      };

      setFragment();
    }

    this.currentRoute = this.router.url.split('#');
  }

  sanatizeInput(input: string) {
    const replaceString = (inputString: string) => {
      inputString = inputString.replace('<', '');
      inputString = inputString.replace('>', '');
      inputString = inputString.replace('"', '');
      inputString = inputString.replace("'", '');
      return inputString;
    };
    const isValid = !/[<>"']/.test(input);
    return isValid ? input : replaceString(input);
  }

  ngAfterViewChecked(): void {
    if (this.isIframeEmbed) {
      const myIframe = <HTMLIFrameElement>document.getElementById('iframe');
      const updateUri = (uriFragment) => {
        this.location.go(this.currentRoute[0] + '#' + uriFragment);
      };
      const loader = this.loaderService;
      myIframe.onload = function () {
        const leftNavIframe = myIframe.contentDocument.querySelector('#left-navbar-parent');
        if (leftNavIframe) {
          leftNavIframe.addEventListener('click', () => {
            setTimeout(() => {
              const frameUrlComponents = myIframe.contentDocument.documentURI.split('#/');
              updateUri(frameUrlComponents[1]);
            });
          });
        }

        const specHTML: HTMLElement = myIframe.contentDocument.querySelector('html');
        if (specHTML) {
          specHTML.style.height = '100%';
        }

        const specBodyElement: HTMLElement = myIframe.contentDocument.querySelector('body');
        if (specBodyElement) {
          specBodyElement.style.height = '100%';
        }

        const specBodyDivElement: HTMLElement = myIframe.contentDocument.querySelector('body > div');
        if (specBodyDivElement) {
          specBodyDivElement.style.height = '100%';
        }

        const specHeader: HTMLElement = myIframe.contentDocument.querySelector('.portal-header');
        if (specHeader) {
          specHeader.style.display = 'none';
        }

        const specBody: HTMLElement = myIframe.contentDocument.querySelector('.hosted-api-docs');
        if (specBody) {
          // specBody.style.height = 'calc(100vh - 140px)';
          specBody.style.height = '100%';
        }

        const apiMaticWidget: HTMLElement = myIframe.contentDocument.querySelector('#apimatic-widget > div');
        if (apiMaticWidget) {
          apiMaticWidget.style.border = 'unset';
        }

        const apiMaticHeader: HTMLElement = myIframe.contentDocument.querySelector('app-header');
        if (apiMaticHeader) {
          apiMaticHeader.parentElement.remove();
        }

        const apiMaticWPButton: HTMLElement = myIframe.contentDocument.querySelector('wpbutton');
        if (apiMaticWPButton) {
          apiMaticWPButton.remove();
        }
        loader.loadingContent.emit(false);
      };
    }
  }
}
