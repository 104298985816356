'use strict';

const CustomStream = require('../stream');
const promises = require('../stream/promises');
const originalDestroy = CustomStream.Readable.destroy;
module.exports = CustomStream.Readable;

// Explicit export naming is needed for ESM
module.exports._uint8ArrayToBuffer = CustomStream._uint8ArrayToBuffer;
module.exports._isUint8Array = CustomStream._isUint8Array;
module.exports.isDisturbed = CustomStream.isDisturbed;
module.exports.isErrored = CustomStream.isErrored;
module.exports.isReadable = CustomStream.isReadable;
module.exports.Readable = CustomStream.Readable;
module.exports.Writable = CustomStream.Writable;
module.exports.Duplex = CustomStream.Duplex;
module.exports.Transform = CustomStream.Transform;
module.exports.PassThrough = CustomStream.PassThrough;
module.exports.addAbortSignal = CustomStream.addAbortSignal;
module.exports.finished = CustomStream.finished;
module.exports.destroy = CustomStream.destroy;
module.exports.destroy = originalDestroy;
module.exports.pipeline = CustomStream.pipeline;
module.exports.compose = CustomStream.compose;
Object.defineProperty(CustomStream, 'promises', {
  configurable: true,
  enumerable: true,
  get() {
    return promises;
  }
});
module.exports.Stream = CustomStream.Stream;

// Allow default importing
module.exports.default = module.exports;