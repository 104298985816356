import { NavVerticalService } from '../../services/nav-vetical.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { filter } from 'rxjs/operators';
import { MetaService } from 'app/services/meta.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    standalone: false
})
export class DashboardComponent implements OnInit, OnDestroy {
  // Vertical Nav Functionality
  @ViewChild('nav') nav: ElementRef;
  @ViewChild('content') content: ElementRef;
  slideNav = false;
  subscription = new Subscription();
  currentPath: string;
  navItems: any;
  sidebarShow = false;

  constructor(
    private changeRef: ChangeDetectorRef,
    private router: Router,
    private navVerticalService: NavVerticalService,
    private metaService: MetaService
  ) {
    // Change contents of template page based on route. /dashboard shows HTML elements
    this.subscription.add(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        this.currentPath = event.url;
      })
    );
  }

  sidebarShowHandler(sidebarShow: boolean) {
    this.sidebarShow = sidebarShow;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.currentPath = this.router.url;
    this.metaService.metadata.next({ 'vertical-nav': 'dashboard-menu' });
  }

  ngAfterContentChecked() {
    this.changeRef.detectChanges();
  }
}
