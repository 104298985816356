<div #parent class="-api-spec-container">
    <div class="hosted-api-docs">
        <div id="apimatic-widget" class="-apimatic-container"></div>
    </div>
    <div>
        <app-footer mode="null"></app-footer>
    </div>
</div>
<div *ngIf="getapikeyInfoModal">
  <modal 
    [visible]="true" 
    (visibleChange)="closeInfoModal()" 
    [secondaryModal]="true"
    [title]="'Not logged in !'">
    <div>
    <p class="-fontSmall -colorAccentGrey -description-padding">
        You must login to access this feature
    </p>
    <div>
        <wpbutton type="submit" to="/account/login">Login</wpbutton>
        <wpbutton type="submit" to="/account/register">Create a free account</wpbutton>
    </div>
    </div>
  </modal>
</div>
