import { MetaService } from 'app/services/meta.service';
import { LoaderService } from './../../services/loader.service';
import { AfterViewInit, Component, OnInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { FragmentService } from '../../services/fragment.service';
// import { isScullyRunning } from '@scullyio/ng-lib';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
declare let APIMaticDevPortal: any;

@Component({
    selector: 'app-merchant-boarding-non-pos-iframe',
    templateUrl: './merchant-boarding-non-pos-iframe.component.html',
    styleUrls: ['./merchant-boarding-non-pos-iframe.component.scss'],
    standalone: false
})
export class MerchantBoardingNonPosIframeComponent implements OnInit, AfterViewInit {
  tryItButtons;
  authLabelText = 'If you want to generate a key and try out the API, please create a free account and login.';
  previousRouteSusbription = 0;
  hasContent = true;
  currentRoute;
  iframeUrl = 'assets/matic/non-pos/index.html';
  frameFragment;
  finalSafeUrl: SafeResourceUrl;
  @ViewChild('parent') parent: ElementRef;
  constructor(
    public fragmentService: FragmentService,
    public loaderService: LoaderService,
    public metaService: MetaService,
    private elementRef: ElementRef,
    private _renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private location: Location,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentRoute = this.router.url.split('#');
    this.frameFragment = this.sanatizeFragment(this.currentRoute[1]);
    this.iframeUrl = this.frameFragment ? this.iframeUrl + '#' + this.frameFragment : this.iframeUrl;
    this.finalSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeUrl);
    this.currentRoute = this.currentRoute[0].split('?');
    const meta = {
      template: 'sdk',
      'secondary-nav': [
        { title: 'Documentation', path: '/apis/launchpad/merchant-boarding-nonpos/' },
        { title: 'API Specification', path: '/apis/launchpad/merchant-boarding-nonpos/api-specification' },
      ],
    };
    this.metaService.metadata.next(meta);
    // this.renderer2.appendChild(document.head, scriptCore);
    this.loaderService.loadingContent.emit(false);
  }

  sanatizeFragment(input: string) {
    const sanatizedFrag = input;

    return sanatizedFrag;
  }

  ngAfterViewInit(): void {
    const myIframe = <HTMLIFrameElement>document.getElementById('iframe');
    const updateUri = (uriFragment) => {
      this.location.go(this.currentRoute[0] + '/#' + uriFragment);
    };
    myIframe.onload = function () {
      const iframeEle = <HTMLIFrameElement>myIframe;
      const leftNavIframe = iframeEle.contentDocument.querySelectorAll('.rc-menu-item-group-list')[0];

      // console.log(leftNavIframe);
      leftNavIframe.addEventListener('click', () => {
        console.log('hello there');

        setTimeout(() => {
          console.log(iframeEle.contentDocument.documentURI);
          const frameUrlComponents = iframeEle.contentDocument.documentURI.split('#/');
          updateUri(frameUrlComponents[1]);
        });
      });
    };

    // const script = this._renderer.createElement('script');
    // script.src = '../../../assets/matic/merchant-boarding-non-pos/static/js/portal.v7-uber.js';
    // script.defer = true;
    // script.onload = () => {
    //   APIMaticDevPortal.show({
    //     container: 'apimatic-widget',
    //     portalStyle: 'default',
    //     apiKey: 'xxx',
    //     baseUrl: './',
    //     enableExport: true,
    //     enableAnalytics: true,
    //     enableConsoleCalls: true,
    //     useProxyForConsoleCalls: true,
    //     languageSettings: {
    //       http_curl_v1: { sdkDownloadLink: '', disableSdkDownload: false },
    //       cs_net_standard_lib: { sdkDownloadLink: '', disableSdkDownload: false },
    //       java_eclipse_jre_lib: { sdkDownloadLink: '', disableSdkDownload: false },
    //       php_generic_lib_v2: { sdkDownloadLink: '', disableSdkDownload: false },
    //       python_generic_lib: { sdkDownloadLink: '', disableSdkDownload: false },
    //       ruby_generic_lib: { sdkDownloadLink: '', disableSdkDownload: false },
    //       ts_generic_lib: { sdkDownloadLink: '', disableSdkDownload: false },
    //       go_generic_lib: { sdkDownloadLink: '', disableSdkDownload: false },
    //     },
    //     allowedExportFormats: [
    //       'openapi31json',
    //       'openapi31yaml',
    //       'openapi3json',
    //       'openapi3yaml',
    //       'swagger20',
    //       'swaggeryaml',
    //       'swagger10',
    //       'raml',
    //       'raml10',
    //       'apiblueprint',
    //       'insomnia',
    //       'insomniayaml',
    //       'wadl2009',
    //       'wsdl',
    //       'apimatic',
    //       'postman10',
    //       'postman20',
    //     ],
    //     initialPlatform: 'http_curl_v1',
    //     themeOverrides: {
    //       palette: { primaryColor: '#0c7ff2', linkColor: '#00C7D4' },
    //       fontSource: [],
    //       cssStyles: {
    //         headings: {
    //           fontFamily: "'Rubik', sans-serif",
    //           h1: {
    //             fontFamily: "'Rubik', sans-serif",
    //             fontSize: '27px',
    //             fontWeight: '500',
    //             fontStyle: 'normal',
    //             lineHeight: '1.3',
    //           },
    //           h2: {
    //             fontFamily: "'Rubik', sans-serif",
    //             fontSize: '24px',
    //             fontWeight: '500',
    //             fontStyle: 'normal',
    //             lineHeight: '1.3',
    //           },
    //           h3: {
    //             fontFamily: "'Rubik', sans-serif",
    //             fontSize: '21.36px',
    //             fontWeight: '500',
    //             fontStyle: 'normal',
    //             lineHeight: '1.3',
    //           },
    //           h4: {
    //             fontFamily: "'Rubik', sans-serif",
    //             fontSize: '18px',
    //             fontWeight: '500',
    //             fontStyle: 'normal',
    //             lineHeight: '1.3',
    //           },
    //           h5: {
    //             fontFamily: "'Rubik', sans-serif",
    //             fontSize: '16px',
    //             fontWeight: '500',
    //             fontStyle: 'normal',
    //             lineHeight: '1.3',
    //           },
    //           h6: {
    //             fontFamily: "'Rubik', sans-serif",
    //             fontSize: '15px',
    //             fontWeight: '500',
    //             fontStyle: 'normal',
    //             lineHeight: '1.3',
    //           },
    //         },
    //         body: {
    //           fontFamily: "'Rubik', sans-serif",
    //           text1: {
    //             fontFamily: "'Rubik', sans-serif",
    //             fontSize: '15px',
    //             fontWeight: '400',
    //             fontStyle: 'normal',
    //             lineHeight: '1.75',
    //           },
    //           text2: {
    //             fontFamily: "'Rubik', sans-serif",
    //             fontSize: '13.33px',
    //             fontWeight: '400',
    //             fontStyle: 'normal',
    //             lineHeight: '1.75',
    //           },
    //           text3: {
    //             fontFamily: "'Rubik', sans-serif",
    //             fontSize: '11.85px',
    //             fontWeight: '400',
    //             fontStyle: 'normal',
    //             lineHeight: '1.75',
    //           },
    //         },
    //         code: {
    //           fontFamily: 'Courier Prime, monospace',
    //           inlineCode: {
    //             fontFamily: 'Courier Prime, monospace',
    //             fontSize: '15px',
    //             fontWeight: '400',
    //             fontStyle: 'normal',
    //             lineHeight: '1.75',
    //           },
    //           blockCode: {
    //             fontFamily: 'Courier Prime, monospace',
    //             fontSize: '15px',
    //             fontWeight: '400',
    //             fontStyle: 'normal',
    //             lineHeight: '1.75',
    //           },
    //         },
    //       },
    //     },
    //     codegenApiRoutes: {
    //       docsgen: 'assets/matic/merchant-boarding-non-pos/static/docs/launchpad--non-pos-merchant-boarding-{template}.json',
    //       codegen: 'assets/matic/merchant-boarding-non-pos/static/sdks/launchpad--non-pos-merchant-boarding-{template}.zip',
    //       transform: 'assets/matic/merchant-boarding-non-pos/static/exports/launchpad--non-pos-merchant-boarding-{format}.{ext}',
    //     },
    //     renameHttpToRest: false,
    //   });
    // };
    // this._renderer.setProperty(script, 'text', this.hmScript);
    // this._renderer.appendChild(this.parent.nativeElement, script);
  }
}
