'use strict';

/*
  This file is a reduced and adapted version of the main lib/internal/per_context/primordials.js file defined at

  https://github.com/nodejs/node/blob/main/lib/internal/per_context/primordials.js

  Don't try to replace with the original file and keep it up to date with the upstream file.
*/

// This is a simplified version of AggregateError
class AggregateError extends Error {
  constructor(errors) {
    if (!Array.isArray(errors)) {
      throw new TypeError(`Expected input to be an Array, got ${typeof errors}`);
    }
    let message = '';
    for (let i = 0; i < errors.length; i++) {
      message += `    ${errors[i].stack}\n`;
    }
    super(message);
    this.name = 'AggregateError';
    this.errors = errors;
  }
}
module.exports = {
  AggregateError,
  ArrayIsArray(self) {
    return Array.isArray(self);
  },
  ArrayPrototypeIncludes(self, el) {
    return self.includes(el);
  },
  ArrayPrototypeIndexOf(self, el) {
    return self.indexOf(el);
  },
  ArrayPrototypeJoin(self, sep) {
    return self.join(sep);
  },
  ArrayPrototypeMap(self, fn) {
    return self.map(fn);
  },
  ArrayPrototypePop(self, el) {
    return self.pop(el);
  },
  ArrayPrototypePush(self, el) {
    return self.push(el);
  },
  ArrayPrototypeSlice(self, start, end) {
    return self.slice(start, end);
  },
  Error,
  FunctionPrototypeCall(fn, thisArgs, ...args) {
    return fn.call(thisArgs, ...args);
  },
  FunctionPrototypeSymbolHasInstance(self, instance) {
    return Function.prototype[Symbol.hasInstance].call(self, instance);
  },
  MathFloor: Math.floor,
  Number,
  NumberIsInteger: Number.isInteger,
  NumberIsNaN: Number.isNaN,
  NumberMAX_SAFE_INTEGER: Number.MAX_SAFE_INTEGER,
  NumberMIN_SAFE_INTEGER: Number.MIN_SAFE_INTEGER,
  NumberParseInt: Number.parseInt,
  ObjectDefineProperties(self, props) {
    return Object.defineProperties(self, props);
  },
  ObjectDefineProperty(self, name, prop) {
    return Object.defineProperty(self, name, prop);
  },
  ObjectGetOwnPropertyDescriptor(self, name) {
    return Object.getOwnPropertyDescriptor(self, name);
  },
  ObjectKeys(obj) {
    return Object.keys(obj);
  },
  ObjectSetPrototypeOf(target, proto) {
    return Object.setPrototypeOf(target, proto);
  },
  Promise,
  PromisePrototypeCatch(self, fn) {
    return self.catch(fn);
  },
  PromisePrototypeThen(self, thenFn, catchFn) {
    return self.then(thenFn, catchFn);
  },
  PromiseReject(err) {
    return Promise.reject(err);
  },
  PromiseResolve(val) {
    return Promise.resolve(val);
  },
  ReflectApply: Reflect.apply,
  RegExpPrototypeTest(self, value) {
    return self.test(value);
  },
  SafeSet: Set,
  String,
  StringPrototypeSlice(self, start, end) {
    return self.slice(start, end);
  },
  StringPrototypeToLowerCase(self) {
    return self.toLowerCase();
  },
  StringPrototypeToUpperCase(self) {
    return self.toUpperCase();
  },
  StringPrototypeTrim(self) {
    return self.trim();
  },
  Symbol,
  SymbolFor: Symbol.for,
  SymbolAsyncIterator: Symbol.asyncIterator,
  SymbolHasInstance: Symbol.hasInstance,
  SymbolIterator: Symbol.iterator,
  SymbolDispose: Symbol.dispose || Symbol('Symbol.dispose'),
  SymbolAsyncDispose: Symbol.asyncDispose || Symbol('Symbol.asyncDispose'),
  TypedArrayPrototypeSet(self, buf, len) {
    return self.set(buf, len);
  },
  Boolean,
  Uint8Array
};