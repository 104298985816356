import { LoaderService } from '../../services/loader.service';
import { Component, OnInit } from '@angular/core';
import { Globals } from '../../globals';

@Component({
    selector: 'app-four-oh-four',
    templateUrl: './four-oh-four.component.html',
    styleUrls: ['./four-oh-four.component.scss'],
    standalone: false
})
export class FourOhFourComponent implements OnInit {
  constructor(
    public globals: Globals,
    private loaderService: LoaderService
    ) {}

  ngOnInit() {
    document.title = 'Not Found | Worldpay Developer Hub';
    this.loaderService.loadingContent.emit(false);
  }
}
