'use strict';

/*
  This file is a reduced and adapted version of the main lib/internal/util/inspect.js file defined at

  https://github.com/nodejs/node/blob/main/lib/internal/util/inspect.js

  Don't try to replace with the original file and keep it up to date with the upstream file.
*/
module.exports = {
  format(format, ...args) {
    // Simplified version of https://nodejs.org/api/util.html#utilformatformat-args
    return format.replace(/%([sdifj])/g, function (...[_unused, type]) {
      const replacement = args.shift();
      if (type === 'f') {
        return replacement.toFixed(6);
      } else if (type === 'j') {
        return JSON.stringify(replacement);
      } else if (type === 's' && typeof replacement === 'object') {
        const ctor = replacement.constructor !== Object ? replacement.constructor.name : '';
        return `${ctor} {}`.trim();
      } else {
        return replacement.toString();
      }
    });
  },
  inspect(value) {
    // Vastly simplified version of https://nodejs.org/api/util.html#utilinspectobject-options
    switch (typeof value) {
      case 'string':
        if (value.includes("'")) {
          if (!value.includes('"')) {
            return `"${value}"`;
          } else if (!value.includes('`') && !value.includes('${')) {
            return `\`${value}\``;
          }
        }
        return `'${value}'`;
      case 'number':
        if (isNaN(value)) {
          return 'NaN';
        } else if (Object.is(value, -0)) {
          return String(value);
        }
        return value;
      case 'bigint':
        return `${String(value)}n`;
      case 'boolean':
      case 'undefined':
        return String(value);
      case 'object':
        return '{}';
    }
  }
};