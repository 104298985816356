import { MetaService } from 'app/services/meta.service';
import { LoaderService } from './../../services/loader.service';
import { ConfigService } from 'app/services/config.service';
import { ThemeIconService } from 'app/services/theme-icon.service';
import { PageService } from 'app/services/page.service';
import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  ElementRef,
  ViewChild,
  Renderer2,
  HostListener,
  afterRender,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
// import { isScullyRunning } from '@scullyio/ng-lib';
declare let APIMaticDevPortal: any;

@Component({
    selector: 'app-api-embed',
    templateUrl: './api-specification-embed.component.html',
    styleUrls: ['./api-specification-embed.component.scss'],
    standalone: false
})
export class ApiSpecificationEmbedComponent implements OnInit, AfterViewInit {
  tryItButtons;
  authLabelText = 'If you want to generate a key and try out the API, please create a free account and login.';
  previousRouteSusbription = 0;
  hasContent = true;
  getapikeyInfoModal = false;
  sub: any;
  family: string;
  api: string;
  apiMaticSriptElement: HTMLScriptElement;
  portalScriptElement: HTMLScriptElement;
  walkthroughScriptElement: HTMLScriptElement;
  apiMaticElement: HTMLElement;
  headerHeight;
  footerHeight;
  @ViewChild('parent') parent: ElementRef;
  constructor(
    public loaderService: LoaderService,
    public metaService: MetaService,
    private themeIconService: ThemeIconService,
    private elementRef: ElementRef,
    private pageService: PageService,
    private _renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService
  ) {
    const ref = afterRender(() => {
      if (window.innerWidth <= 990) {
        this.getHeight('header');
        if (this.headerHeight) {
          this.setHeight('header');
          ref.destroy();
        }
      } else {
        this.getHeight('headerfooter');
        if (this.headerHeight && this.footerHeight) {
          this.setHeight('headerfooter');
          ref.destroy();
        }
      }
    });
  }

  ngOnInit(): void {
    // this.renderer2.appendChild(document.head, scriptCore);
    this.sub = this.route.params.subscribe((params) => {
      this.family = params['family'];
      this.api = params['api'];

      this.configService.getConfig('apimatic-products').subscribe(
        (apiMaticProductResponse) => {
          if (apiMaticProductResponse.Items && apiMaticProductResponse.Items.length > 0) {
            const apiMaticProducts = apiMaticProductResponse.Items[0].apimatic;
            apiMaticProducts.forEach((item) => {
              if (this.router.url.includes(item.name)) {
                const secondaryNav = [
                  {
                    title: 'Documentation',
                    path: item.family ? '/apis/' + item.family + '/' + item.name : '/apis/' + item.name,
                  },
                  {
                    title: 'API Specification',
                    path: item.family
                      ? '/api-specification/' + item.family + '/' + item.name
                      : '/api-specification/' + item.name,
                  },
                ];
                const meta = {
                  versions: item.versions,
                  template: 'sdk',
                  title: item.name,
                  'secondary-nav': secondaryNav,
                  'apimatic-spec': true,
                };
                if (item['hide-api-key']) {
                  meta['hide-api-key'] = item['hide-api-key'];
                }
                this.metaService.metadata.next(meta);
                this.setPortal(item);
                return;
              }
            });
          }
          // if (!this.isApiMaticSpec) {
          //   this.router.navigateByUrl('/404', { skipLocationChange: true });
          // }
        },
        (error) => {
          this.router.navigateByUrl('/404', { skipLocationChange: true });
        }
      );
    });

    this.pageService.getApiKeyConfirmEvent.subscribe(() => {
      this.getapikeyInfoModal = true;
    });
  }

  private setPortal(apiItem) {
    const curentTimeStamp = Date.now();
    const currentTheme = this.themeIconService.getDefaultSetting();
    this.apiMaticElement = this.parent.nativeElement.querySelector('#apimatic-widget');
    this.apiMaticSriptElement = this._renderer.createElement('script');
    this.apiMaticSriptElement.src = `assets/matic/portal-script/js/portal.v7-uber.js`;
    this.apiMaticSriptElement.defer = true;
    this.apiMaticSriptElement.onload = () => {
      const curentTimeStamp = Date.now();
      this.portalScriptElement = this._renderer.createElement('script');
      this.portalScriptElement.src = this.family
        ? `assets/matic/${this.family}/${this.api}/current/static/js/portal.js?vt=${curentTimeStamp}`
        : `assets/matic/${this.api}/current/static/js/portal.js?vt=${curentTimeStamp}`;
      this._renderer.appendChild(this.parent.nativeElement, this.portalScriptElement);
      this.portalScriptElement.onload = () => {
        if (APIMaticDevPortal && APIMaticDevPortal.setThemeMode) {
          APIMaticDevPortal.setThemeMode(currentTheme);
        }
        if (apiItem['walkthrough']) {
          const curentTimeStamp = Date.now();
          this.walkthroughScriptElement = this._renderer.createElement('script');
          this.walkthroughScriptElement.src = this.family
            ? `assets/matic/${this.family}/${this.api}/current/static/walkthroughs/walkthrough.js?vt=${curentTimeStamp}`
            : `assets/matic/${this.api}/current/static/walkthroughs/walkthrough.js?vt=${curentTimeStamp}`;
          this.walkthroughScriptElement.defer = true;
          this._renderer.appendChild(this.parent.nativeElement, this.walkthroughScriptElement);
        }
        this.loaderService.loadingContent.emit(false);
      };
      this.loaderService.loadingContent.emit(false);
    };
    this._renderer.appendChild(this.parent.nativeElement, this.apiMaticSriptElement);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth > 990) {
      this.getHeight('headerfooter');
      this.setHeight('headerfooter');
    } else {
      this.getHeight('header');
      this.setHeight('header');
    }
  }

  private getHeight(heightFor: string) {
    if (heightFor === 'header') {
      const headerElement = document.querySelector('.header');
      if (headerElement && headerElement.clientHeight) {
        this.headerHeight = headerElement.clientHeight;
      }
    } else {
      const headerElement = document.querySelector('.header');
      if (headerElement && headerElement.clientHeight) {
        this.headerHeight = headerElement.clientHeight;
      }
      const footerElement = document.querySelector('.footer');
      if (footerElement && footerElement.clientHeight) {
        this.footerHeight = footerElement.clientHeight;
      }
    }
  }

  private setHeight(heightFor: string) {
    const apimaticElement = document.querySelector('.-apimatic-container');
    if (heightFor === 'header') {
      const headerFooterElementHeight = this.headerHeight;
      apimaticElement.setAttribute('style', `height: calc(100vh - ${headerFooterElementHeight}px)`);
    } else {
      const headerFooterElementHeight = this.headerHeight + this.footerHeight;
      apimaticElement.setAttribute('style', `height: calc(100vh - ${headerFooterElementHeight}px)`);
    }
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.apiMaticSriptElement.remove();
    if (this.portalScriptElement) {
      this.portalScriptElement.remove();
    }
    if (this.walkthroughScriptElement) {
      this.walkthroughScriptElement.remove();
    }
    if (this.apiMaticElement) {
      this.apiMaticElement.innerHTML = '';
    }
  }
}
