import { Component, OnInit } from '@angular/core';
import { MetaService } from 'app/services/meta.service';
import { BaseComponent } from '../base/base.component';
import { FragmentService } from '../../services/fragment.service';
import { LoaderService } from 'app/services/loader.service';

@Component({
  selector: 'app-product-landing',
  templateUrl: './product-landing.component.html',
  standalone: false
})
export class ProductLandingComponent extends BaseComponent implements OnInit {
  constructor(
    public fragmentService: FragmentService,
    public loaderService: LoaderService,
    public metaService: MetaService
    ) {
    super(fragmentService, loaderService, metaService);
   }

  ngOnInit(): void {
  }

}
