import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { MetaService } from 'app/services/meta.service';
import { BaseComponent } from '../base/base.component';
import { FragmentService } from '../../services/fragment.service';
import { LoaderService } from 'app/services/loader.service';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-public',
    templateUrl: './public.component.html',
    standalone: false
})
export class PublicComponent extends BaseComponent implements OnInit, AfterViewChecked {
  isDocIndex = false;
  docIndexReady = false;
  constructor(
    public fragmentService: FragmentService,
    public loaderService: LoaderService,
    public metaService: MetaService
  ) {
    super(fragmentService, loaderService, metaService);
  }

  ngOnInit(): void {
    this.metaService.metadata.pipe(distinctUntilChanged()).subscribe((pMeta) => {
      this.docIndexReady = false;
      this.isDocIndex = pMeta && pMeta['page-index'] === undefined ? false : pMeta['page-index'];
    });
  }

  ngAfterViewChecked(): void {
    this.docIndexReady = true;
  }
}
