import { MetaService } from 'app/services/meta.service';
import { LoaderService } from './../../services/loader.service';
import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  ElementRef,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FragmentService } from '../../services/fragment.service';
// import { isScullyRunning } from '@scullyio/ng-lib';
declare let APIMaticDevPortal: any;

@Component({
    selector: 'app-merchant-boarding',
    templateUrl: './merchant-boarding.component.html',
    styleUrls: ['./merchant-boarding.component.scss'],
    standalone: false
})
export class MerchantBoardingComponent implements OnInit, AfterViewInit {
  tryItButtons;
  authLabelText = 'If you want to generate a key and try out the API, please create a free account and login.';
  previousRouteSusbription = 0;
  hasContent = true;
  @ViewChild('parent') parent: ElementRef;
  constructor(
    public fragmentService: FragmentService,
    public loaderService: LoaderService,
    public metaService: MetaService,
    private elementRef: ElementRef,
    private _renderer: Renderer2,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const meta = {
      template: 'sdk',
      'secondary-nav': [{ title: 'API Specification', path: '/merchant-boarding' }],
    };
    this.metaService.metadata.next(meta);
    this.loaderService.loadingContent.emit(false);
  }

  ngAfterViewInit(): void {
    const script = this._renderer.createElement('script');
    script.src = 'assets/matic/portal-script/js/portal.v7-uber.js';

    script.defer = true;
    script.onload = () => {
      const showScript = this._renderer.createElement('script');
      showScript.src = 'assets/matic/merchant-boarding/static/js/portal.js';
      this._renderer.appendChild(this.parent.nativeElement, showScript);
      showScript.onload = () => {
        const walkThroughScript = this._renderer.createElement('script');
        walkThroughScript.src = `assets/matic/merchant-boarding/static/walkthroughs/walkthrough.js`;
        script.defer = true;
        this._renderer.appendChild(this.parent.nativeElement, walkThroughScript);
      };
    };
    this._renderer.appendChild(this.parent.nativeElement, script);
  }
}
